import { useFlash } from 'contexts/flash';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptions } from '../../../components/elements/InputSelect';
import axios from 'axios';
import { Business, BusinessRes, MarkerOption, TStorage } from 'types/marker';
import { CircleIcon } from '../../../components/elements/CircleIcon';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { PinForm, TPinForm } from '../components/PinForm';
import { LayoutContent } from '../../../components/templates/LayoutContent';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import { Community } from 'types/community';

export const EditBusiness = () => {
  const { id } = useParams();
  const { setFlash } = useFlash();
  const [optionMarkers, setOptionMarkers] = useState<SelectOptions[]>([]);
  const [initValueBusiness, setInitValueBusiness] = useState<Business>();
  const [communities, setCommunities] = useState<SelectOptions[]>([]);
  const handleFetchOption = useCallback(async () => {
    const [optionMarker, business, optionCommunities] = await Promise.all([
      axios.get('/admin/pins?size=100'),
      axios.get(`/admin/business/${id}`),
      axios.get(`/community`)
    ]);
    const resMarker = optionMarker.data.pins as MarkerOption[];
    const resCommunities = optionCommunities.data.communities as Community[];
    const resBusiness = business.data.business as BusinessRes;
    const dataConvert = resMarker.map((item: MarkerOption) => ({
      value: item.id,
      label: item.name,
      extra: (
        <CircleIcon
          color={item.color}
          icon={<img style={{ objectFit: 'cover' }} height={20} src={item?.icon} alt={'icon'} />}
        />
      ),
      color: item.color
    }));
    const comunitiesConvert = resCommunities.map((item: Community) => ({
      value: item.id,
      label: item.name
    }));
    const convertBusiness = {
      ...omit(resBusiness, ['qrCode']),
      links: resBusiness?.links ? resBusiness.links.split(',') : [],
      qrLongitude: resBusiness.qrCode?.longitude,
      qrLatitude: resBusiness.qrCode?.latitude,
      qrUrl: resBusiness.qrCode?.url,
      fileQr: resBusiness.qrCode?.image
    };
    setInitValueBusiness(convertBusiness);
    setOptionMarkers(dataConvert);
    setCommunities(comunitiesConvert);
  }, [id]);

  const { asyncCallback: asyncFetchOption } = useAsyncCallback(handleFetchOption, []);

  useEffect(() => {
    asyncFetchOption();
  }, []);

  const handleSubmitForm = useCallback(async (data: TPinForm & { uploaded?: TStorage[] }) => {
    const formData = new FormData();
    let arrayIdFile: number[] = [];
    if (data.files && data.files.length) {
      for (let x = 0; x < data.files.length; x++) {
        const convertBlob = new Blob([data.files[x]], {
          type: data.files[x]?.type
        });
        formData.append('files', convertBlob, data.files[x]?.name);
      }
      const resListIdFile = await axios.post('/storages/business', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      arrayIdFile = resListIdFile.data.files;
    }
    const listFileUploaded = data.uploaded?.map((item) => Number(item.id));
    const totalArrayListFileUpdatedAndNew = [...arrayIdFile, ...(listFileUploaded || [])];
    const payload = {
      ...data,
      links: data.links?.length ? data.links?.join(',') : '',
      storageIds: totalArrayListFileUpdatedAndNew || undefined
    };
    const payloadQrCode = {
      url: data.qrUrl,
      latitude: data.qrLatitude,
      longitude: data.qrLongitude
    };
    await Promise.all([
      await axios.put(`/admin/business/${id}`, payload),
      await axios.put(`/admin/business/${id}/qrcode`, payloadQrCode)
    ]);

    setFlash({ type: 'success', message: 'Update Business Successfully' });
  }, []);

  const { asyncCallback: asyncSubmitForm } = useAsyncCallback(handleSubmitForm, []);

  return (
    <LayoutContent title={'Edit Business'}>
      <Stack maxWidth={'100%'} alignItems={'center'} gap={5} p={5}>
        <PinForm
          initValue={initValueBusiness}
          isEdit
          handleSubmitForm={asyncSubmitForm}
          optionMarkers={optionMarkers}
          communities={communities}
        />
      </Stack>
    </LayoutContent>
  );
};
