import {
  Box,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CustomPagination } from '../../../components/elements/Pagination';
import { Header, PagingInfo } from 'types/paging';
import { Business } from 'types/marker';
import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { isDate } from 'lodash';
import { InputSelect, SelectOptions } from '../../../components/elements/InputSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { optionCategory } from 'const/option';

type ListBusinessTableProps = {
  handleChangePage: (value: number) => void;
  pagination: PagingInfo;
  listBusiness: Business[];
  headers: Header[];
  onClickAction: (id: string) => void;
  onDelete: (id: string) => void;
  handleChangeFilter: (fieldName: string, value: string) => void;
  valueFilter: {
    startDate?: Date;
    enDate?: Date;
    communityId?: string;
    category?: string;
    businessOfIndigenous?: string;
  };
  onResetFilter: () => void;
  communities: SelectOptions[];
};
export const ListBusinessTable = ({
  handleChangePage,
  listBusiness,
  pagination,
  headers,
  onClickAction,
  onDelete,
  handleChangeFilter,
  valueFilter,
  onResetFilter,
  communities
}: ListBusinessTableProps) => {
  const navigate = useNavigate();
  return (
    <Box overflow={'auto'} width={'100%'} p={'25px 50px'}>
      <Stack
        width={'100%'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={4}
        flexDirection={'row'}>
        <Stack gap={2}>
          <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
            <Typography>Field Search</Typography>
            <ButtomCustom
              sx={{ width: '100px', height: '30px' }}
              label={'Reset'}
              onClick={onResetFilter}
            />
          </Stack>
          <Stack style={{ background: '#2B3492' }} flexDirection={'row'} px={2} py={2} gap={3}>
            <Stack gap={2.5}>
              <Stack>
                <Typography color={'#fff'} mb={1}>
                  Start Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledDatePicker
                    maxDate={isDate(valueFilter?.enDate) ? dayjs(valueFilter?.enDate) : dayjs()}
                    value={valueFilter.startDate ? dayjs(valueFilter.startDate) : null}
                    onChange={(newValue) =>
                      handleChangeFilter('startDate', dayjs(newValue).format('YYYY-MM-DD'))
                    }
                  />
                </LocalizationProvider>
              </Stack>
              <Stack>
                <Typography color={'#fff'} mb={1}>
                  End Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledDatePicker
                    maxDate={dayjs()}
                    value={valueFilter.enDate ? dayjs(valueFilter.enDate) : null}
                    onChange={(newValue) =>
                      handleChangeFilter('endDate', dayjs(newValue).format('YYYY-MM-DD'))
                    }
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
            <Stack gap={2.5}>
              <Stack width={250}>
                <InputSelect
                  sx={{
                    width: 250
                  }}
                  colorTitle={'#fff'}
                  placeholder={'Choose Community'}
                  title={'Choose Community'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    handleChangeFilter('communityId', e.target.value as string);
                  }}
                  value={valueFilter?.communityId}
                  options={communities}
                />
              </Stack>
              <Stack width={250}>
                <InputSelect
                  sx={{
                    width: 250
                  }}
                  colorTitle={'#fff'}
                  placeholder={'Choose Category'}
                  title={'Choose Category'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    handleChangeFilter('category', e.target.value as string);
                  }}
                  value={valueFilter?.category}
                  options={optionCategory}
                />
              </Stack>
            </Stack>

            <Stack width={250}>
              <Typography color={'#fff'} mb={1}>
                Indigenous Led Business
              </Typography>
              <SwitchCustom
                checked={
                  valueFilter?.businessOfIndigenous
                    ? JSON.parse(valueFilter?.businessOfIndigenous as string)
                    : false
                }
                onChange={(e) => {
                  handleChangeFilter('businessOfIndigenous', `${e.target.checked}`);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <ButtomCustom
          sx={{ width: '170px', height: '48px' }}
          label={'Create'}
          onClick={() => {
            navigate('/admin/business/create');
          }}
        />
      </Stack>
      <TableWrapper overflow={'auto'}>
        <TableListAdminCompany sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow sx={{ height: 60 }}>
              {headers.map((header, idx) => (
                <TableCellHeadStyled key={idx} sx={{ width: header.width || 'unset' }}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'} pl={1}>
                    {header.label}
                    {header.option}
                  </Stack>
                </TableCellHeadStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listBusiness &&
              listBusiness.map((business, index: number) => {
                return (
                  <TableRowStyled key={index}>
                    <TableCellStyled>
                      <TextWrapper>{business?.numberScans}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business?.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business?.description}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>
                        {optionCategory.find((item) => item.value === business?.category)?.label}
                      </TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business.businessOfIndigenous ? 'yes' : 'no'}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business?.community?.name}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business?.latitude}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <TextWrapper>{business?.longitude}</TextWrapper>
                    </TableCellStyled>
                    <TableCellStyled>
                      <Stack
                        p={'0 16px'}
                        gap={2}
                        width={'100%'}
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}>
                        <ButtomCustom
                          onClick={() => onClickAction(business.id)}
                          style={{ width: 100, height: 36 }}
                          label={'Edit'}
                        />
                        <ButtomCustom
                          type={'button'}
                          onClick={() => onDelete(business.id)}
                          style={{ width: 100, height: 36, background: 'red' }}
                          label={'Delete'}
                        />
                      </Stack>
                    </TableCellStyled>
                  </TableRowStyled>
                );
              })}
          </TableBody>
        </TableListAdminCompany>
      </TableWrapper>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'center'}
        width={'100%'}
        alignItems={'center'}
        gap={{ xs: 2, md: 'unset' }}
        pt={2.5}>
        {pagination.pageCount !== 0 && (
          <CustomPagination
            page={pagination.currentPage}
            count={pagination.pageCount}
            onChangePage={handleChangePage}
          />
        )}
      </Stack>
    </Box>
  );
};
const SwitchCustom = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#fff',
    opacity: 0.8
  }
}));
const TableWrapper = styled(Box)(() => ({
  minHeight: 'calc(100vh - 580px)'
}));

const TableListAdminCompany = styled(Table)(() => ({}));

const TableCellStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  padding: 0,
  fontSize: 16,
  maxWidth: '330px',
  lineHeight: '30px',
  color: 'red',
  height: 60
}));

const TableRowStyled = styled(TableRow)(() => ({
  height: 60,
  '&:hover': {
    background: `linear-gradient(180deg, #FAFDFF 0%, #EEF7FF 9.31%, #D4EAFD 59.58%)`,
    cursor: 'pointer'
  }
}));

const TextWrapper = styled(Typography)(() => ({
  padding: '0 16px',
  color: '#000'
}));

const TableCellHeadStyled = styled(TableCell)(() => ({
  border: `1px solid #ccc`,
  textAlign: 'center',
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '17px',
  color: '#FFF',
  padding: 0,
  backgroundColor: '#2B3492'
}));

const StyledDatePicker = styled(DatePicker)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    border: 'none',
    background: '#ffffff',
    height: '45px',
    overflow: 'hidden',
    marginBottom: '4px',
    marginRight: '4px'
  },
  '& fieldset': {
    borderColor: '#ccc !important',
    borderWidth: '1px',
    top: 0,
    '& legend': {
      display: 'none'
    }
  }
}));
