import { LayoutContent } from '../../components/templates/LayoutContent';
import { Stack } from '@mui/material';
import { CommunityForm, CommunityTable } from './components/CommunityTable';
import { useCallback } from 'react';
import { TStorage } from 'types/marker';
import axios from 'axios';
import { useFlash } from 'contexts/flash';
import { useNavigate } from 'react-router-dom';
import { useAsyncCallback } from 'hooks/useAsyncCallback';

export const CommunityPage = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const handleOnCreate = useCallback(async (data: CommunityForm & { uploaded?: TStorage[] }) => {
    const formData = new FormData();
    let arrayIdFile: number[] = [];
    if (data.files) {
      for (let x = 0; x < data.files.length; x++) {
        const convertBlob = new Blob([data.files[x]], {
          type: data.files[x]?.type
        });
        formData.append('files', convertBlob, data.files[x]?.name);
      }
      const resListIdFile = await axios.post('/storages/community', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      arrayIdFile = resListIdFile.data.files;
    }
    const payload = { ...data, links: data.links?.join(','), storageIds: arrayIdFile || undefined };
    await axios.post('/admin/community', payload);
    setFlash({ type: 'success', message: 'Create Community Successfully' });
    navigate('/admin/community');
  }, []);

  const { asyncCallback: asyncHandleOnCreate } = useAsyncCallback(handleOnCreate, []);
  return (
    <LayoutContent isBorder title={'Create'}>
      <Stack maxWidth={1180} alignItems={'center'} pb={5}>
        <CommunityTable handleSubmitForm={asyncHandleOnCreate} />
      </Stack>
    </LayoutContent>
  );
};
