import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommunityFormSchema } from 'utils/validate';
import { Button, IconButton, InputAdornment, Stack, styled, Typography } from '@mui/material';
import { InputText } from '../../../components/elements/InputText';

import { ButtomCustom } from '../../../components/elements/ButtonCustom';
import { InputSelect } from '../../../components/elements/InputSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import { optionCommunity } from 'const/option';
import { TextError } from '../../../components/elements/TextError';
import FormHelperText from '@mui/material/FormHelperText';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { trim, unionBy } from 'lodash';
import { TStorage } from 'types/marker';
import { Community } from 'types/community';
import { useLoading } from 'contexts/loading';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export type CommunityForm = {
  name: string;
  area: string;
  radius: number;
  description?: string;
  longitude: number;
  links?: string[];
  latitude: number;
  files?: File[];
};

type CommunityTableProps = {
  isEdit?: boolean;
  handleSubmitForm: (value: CommunityForm & { uploaded?: TStorage[] }) => void;
  initValue?: Community;
};
export const CommunityTable = ({ isEdit, handleSubmitForm, initValue }: CommunityTableProps) => {
  const { loading } = useLoading();
  const [link, setLink] = useState<string>('');
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm<CommunityForm>({
    resolver: yupResolver(CommunityFormSchema)
  });

  const isValidUrl = (url: string) => {
    const regex = /^(https?:\/\/)?.+\..+$/;
    return regex.test(url);
  };
  const links = watch('links');
  const handleKeyPress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (event.key === 'Enter' && link) {
        const isValid = isValidUrl(link);
        if (!isValid) {
          setError('links', { message: 'Please enter a valid link' });
          return;
        }
        event.preventDefault();
        if (links && links.includes(trim(link))) {
          setError('links', { message: 'Please enter a valid link' });
          return;
        }
        setLink('');
        clearErrors('links');
        const newListLinks = links ? [...links, link] : [link];
        setValue('links', newListLinks);
      }
    },
    [links, link]
  );
  const deleteLink = (link: string) => {
    const newListLinks = links?.filter((linkOld) => linkOld !== link);
    setValue('links', newListLinks);
  };
  const [listFile, setListFile] = useState<File[]>([]);
  const [listFileUploaded, setListFileUploaded] = useState<TStorage[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    const fileConvertArray = Array.from(fileList);
    const files = unionBy([...listFile, ...fileConvertArray], 'name');
    setValue('files', files);
    setListFile(files);
    // @ts-expect-error set empty input
    event.target.value = null;
  };
  useEffect(() => {
    if (initValue) {
      reset(initValue);
      setListFileUploaded(initValue.communityPhotos?.map((item) => item.photo) || []);
    }
  }, [initValue]);
  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onSubmit = useCallback(
    async (data: CommunityForm) => {
      handleSubmitForm({ ...data, uploaded: listFileUploaded });
    },
    [listFileUploaded]
  );

  const handleRemoveItem = useCallback(
    (index: number, type?: 'uploaded' | 'new') => {
      if (type === 'uploaded') {
        const newList = [...listFileUploaded];
        newList.splice(index, 1);
        setListFileUploaded(newList);
        return;
      }
      const newList = [...listFile];
      newList.splice(index, 1);
      setListFile(newList);
      setValue('files', newList);
      if (!fileInputRef.current) return;
      // @ts-expect-error set empty input
      fileInputRef.current.value = null;
    },
    [listFile, listFileUploaded]
  );
  return (
    <>
      <Stack gap={5} flexDirection={'row'} mb={4}>
        <Stack gap={2.5} width={320}>
          <InputText
            placeholder={'Community Name'}
            inputError={errors.name?.message}
            inputProps={{
              ...register('name')
            }}
            title={'Community Name'}
          />
          <InputText
            placeholder={'Radius'}
            inputError={errors.radius?.message}
            startAdornment={
              <InputAdornment position="start">
                <Typography color={'#000'} fontSize={'14px'}>
                  km
                </Typography>
              </InputAdornment>
            }
            inputProps={{
              ...register('radius'),
              type: 'number'
            }}
            title={'Radius'}
          />
          <InputText
            inputError={errors.description?.message}
            inputProps={{
              ...register('description'),
              minRows: 5,
              maxRows: 5,
              multiline: true,
              sx: { padding: 0 }
            }}
            title={'Description'}
          />
          <Stack width={320}>
            <Typography mb={1} color={'#2B3492'}>
              Image Files
            </Typography>
            <ButtonChooseFile onClick={() => handleFileClick()}>Choose Files</ButtonChooseFile>
            <Stack gap={1} mt={1}>
              {listFileUploaded?.map((item, index) => (
                <Stack key={index} flexDirection={'row'} gap={1}>
                  <TextFileStyled>{item.fileName}</TextFileStyled>
                  <IconButton onClick={() => handleRemoveItem(index, 'uploaded')}>
                    <img src={'/icons/icon_cancel.svg'} alt={'icon'} />
                  </IconButton>
                </Stack>
              ))}
              {listFile &&
                listFile?.map((item: File, index: number) => (
                  <Stack key={index} flexDirection={'row'} gap={1}>
                    <TextFileStyled>{item.name}</TextFileStyled>
                    <IconButton onClick={() => handleRemoveItem(index)}>
                      <img src={'/icons/icon_cancel.svg'} alt={'icon'} />
                    </IconButton>
                  </Stack>
                ))}
            </Stack>
            <input
              accept="image/*"
              ref={fileInputRef}
              hidden
              onChange={handleFileChange}
              type="file"
              multiple
            />
          </Stack>
        </Stack>
        <Stack gap={2.5} width={320}>
          <Stack>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputSelect
                  title={'Choose Area'}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    onChange(e);
                  }}
                  value={value}
                  onBlur={onBlur}
                  options={optionCommunity}
                />
              )}
              name={'area'}
            />
            <FormHelperText error={!!errors.area?.message} sx={{ ml: 0, pt: 0.5, height: 24 }}>
              <TextError errorText={errors.area?.message || ''} />
            </FormHelperText>
          </Stack>
          <InputText
            placeholder={'Latitude'}
            inputError={errors.latitude?.message}
            inputProps={{
              ...register('latitude')
            }}
            title={'Latitude'}
          />
          <InputText
            placeholder={'Longitude'}
            inputError={errors.longitude?.message}
            inputProps={{
              ...register('longitude')
            }}
            title={'Longitude'}
          />
          <Stack>
            <InputText
              placeholder={'Enter to add links'}
              title={'Add Links'}
              inputProps={{
                value: link,
                onChange: (e: { target: { value: SetStateAction<string> } }) => {
                  clearErrors('links');
                  setLink(e.target.value);
                },
                onKeyDown: handleKeyPress
              }}
              inputError={errors.links?.message}
            />
            <Stack
              flexDirection={'row'}
              gap={2}
              mb={links?.length ? 1 : 0}
              width={'100%'}
              flexWrap={'wrap'}>
              {links?.length !== 0 &&
                links?.map((key, index) => (
                  <Stack
                    key={index}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '10px',
                      width: '100%'
                    }}>
                    <a
                      href={key}
                      target="_blank"
                      style={{
                        width: 'calc(100% - 65px)',
                        color: '#2B3492',
                        cursor: 'pointer',
                        fontSize: '20px',
                        textDecoration: 'underline'
                      }}>
                      {key}
                    </a>
                    <HighlightOffIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => deleteLink(key)}
                      fontSize={'small'}
                    />
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
        gap={4}>
        <ButtomCustom
          loading={loading}
          style={{ width: 200 }}
          onClick={handleSubmit(onSubmit)}
          label={isEdit ? 'Update' : 'Add'}
        />
      </Stack>
    </>
  );
};
const ButtonChooseFile = styled(Button)(() => ({
  background: '#2B3492',
  color: '#fff',
  width: 170,
  '&:hover': {
    backgroundColor: '#2B3492',
    opacity: 0.8
  }
}));

const TextFileStyled = styled(Typography)(() => ({
  width: 250,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));
