export const optionCommunity = [
  {
    value: 'NORTH',
    label: 'NORTH'
  },
  {
    value: 'CENTRAL_EAST',
    label: 'CENTRAL EAST'
  },
  {
    value: 'CENTRAL_WEST',
    label: 'CENTRAL WEST'
  },
  {
    value: 'SOUTH',
    label: 'SOUTH'
  }
];

export const optionCategory = [
  {
    value: 'ARTIST',
    label: 'The Arts (galleries, theatre, museums)'
  },
  {
    value: 'BUSINESS',
    label: 'Businesses/Tourism'
  },
  {
    value: 'PLACES_EAT_DRINK_SLEEP',
    label: ' Places to Eat, Drink or Sleep'
  },
  {
    value: 'FARMER_MARKETS',
    label: 'Farm Stands / Markets'
  },
  {
    value: 'FESTIVALS',
    label: 'Festivals / Events'
  },
  {
    value: 'OUT_DOORS',
    label: 'Outdoors (nature, camping, hikes, trails..)'
  },
  {
    value: 'HELP_WANTED',
    label: 'Help Wanted'
  }
];
