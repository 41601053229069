import { Stack } from '@mui/material';
import { PinForm, TPinForm } from '../components/PinForm';
import { LayoutContent } from '../../../components/templates/LayoutContent';
import { useCallback, useEffect, useState } from 'react';
import { MarkerOption } from 'types/marker';
import axios from 'axios';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { SelectOptions } from '../../../components/elements/InputSelect';
import { CircleIcon } from '../../../components/elements/CircleIcon';
import { useFlash } from 'contexts/flash';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { Community } from 'types/community';

export const CreateBusiness = () => {
  const { setFlash } = useFlash();
  const navigate = useNavigate();
  const [optionMarkers, setOptionMarkers] = useState<SelectOptions[]>([]);
  const [communities, setCommunities] = useState<SelectOptions[]>([]);
  const handleFetchOption = useCallback(async () => {
    const [optionMarker, optionCommunities] = await Promise.all([
      axios.get('/admin/pins?size=100'),
      axios.get(`/community`)
    ]);
    const resMarker = optionMarker.data.pins as MarkerOption[];
    const resCommunities = optionCommunities.data.communities as Community[];
    const comunitiesConvert = resCommunities.map((item: Community) => ({
      value: item.id,
      label: item.name
    }));
    const dataConvert = resMarker.map((item: MarkerOption) => ({
      value: item.id,
      label: item.name,
      extra: (
        <CircleIcon
          color={item.color}
          icon={<img height={20} style={{ objectFit: 'cover' }} src={item?.icon} alt={'icon'} />}
        />
      ),
      color: item.color
    }));
    setCommunities(comunitiesConvert);
    setOptionMarkers(dataConvert);
  }, []);

  const { asyncCallback: asyncFetchOption } = useAsyncCallback(handleFetchOption, []);

  useEffect(() => {
    asyncFetchOption();
  }, []);

  const handleSubmitForm = useCallback(async (data: TPinForm) => {
    const formData = new FormData();
    let arrayIdFile: number[] = [];
    if (data.files) {
      for (let x = 0; x < data.files.length; x++) {
        const convertBlob = new Blob([data.files[x]], {
          type: data.files[x]?.type
        });
        formData.append('files', convertBlob, data.files[x]?.name);
      }
      const resListIdFile = await axios.post('/storages/business', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      arrayIdFile = resListIdFile.data.files;
    }
    const payload = {
      ...omit(data, ['qrUrl', 'qrLongitude', 'qrLatitude']),
      links: data.links?.join(','),
      storageIds: arrayIdFile || undefined
    };
    const { data: res } = await axios.post('/admin/business', payload);
    if (!res.business.id) return;
    const payloadQrCode = {
      url: data.qrUrl,
      latitude: data.qrLatitude,
      longitude: data.qrLongitude
    };
    const { data: response } = await axios.put(
      `/admin/business/${res.business.id}/qrcode`,
      payloadQrCode
    );
    setFlash({ type: 'success', message: 'Create Business Successfully' });
    navigate(`/admin/business/edit/${response.qrcode.businessId}`);
  }, []);

  const { asyncCallback: asyncSubmitForm } = useAsyncCallback(handleSubmitForm, []);

  return (
    <LayoutContent title={'Create'}>
      <Stack width={'100%'} alignItems={'center'} gap={5} p={5}>
        <PinForm
          handleSubmitForm={asyncSubmitForm}
          optionMarkers={optionMarkers}
          communities={communities}
        />
      </Stack>
    </LayoutContent>
  );
};
