import * as yup from 'yup';

// const latLongRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

export const loginSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email invalid'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
});
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required')
});

export const pinFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required'),
  address: yup.string(),
  description: yup.string(),
  links: yup.array().optional(),
  longitude: yup.number().typeError('Longitude must be a number'),
  latitude: yup.number().typeError('Longitude must be a number'),
  point: yup.number().typeError('Point must be a number'),
  area: yup.string().required('Area is required'),
  category: yup.string().required('Category is required'),
  businessOfIndigenous: yup.boolean().optional(),
  communityId: yup.string().required('Community is required'),
  qrLongitude: yup
    .number()
    .typeError('QR longitude must be a number')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : undefined)),
  qrLatitude: yup
    .number()
    .typeError('QR latitude must be a number')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : undefined)),
  qrUrl: yup
    .string()
    .required('QR URL is required')
    .url('The URL entered is invalid. Please check and try again')
});
export const MarkerFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required')
});
export const CommunityFormSchema = yup.object().shape({
  name: yup.string().required('Name Address is required'),
  area: yup.string().required('Area is required'),
  radius: yup.number().required().typeError('Radius is required'),
  links: yup.array().optional(),
  description: yup.string().typeError('Description is required'),
  longitude: yup.number().required().typeError('Longitude must be a number'),
  latitude: yup.number().required().typeError('Latitude must be a number')
});
export const TermFormSchema = yup.object().shape({
  termsTitle: yup.string().required('Term Title is required'),
  termsContent: yup.string().required('Term Content is required')
});
export const MilestoneSchema = yup.object().shape({
  point: yup.number().required('Point  is required'),
  message: yup.string().required('Message  is required')
});
